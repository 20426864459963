// Copyright (C) 2019 Pepperdata Inc. - All rights reserved.
// @flow

import _ from "lodash";
import React from "react";
import {
  createAccount,
  fetchPasswordRequirements,
  ssoLogin,
} from "../auth/auth-helpers";
import {
  ACCOUNT_CREATION_ERROR_MSG,
  CONTAINER_CREATE,
  CREATE_FORM_ACTION,
  CREATE_FORM_SENTENCE,
  CREATE_FORM_SSO_LOGIN,
  DUPLICATE_ACCOUNT_ERROR_MSG,
  EMAIL_PW_REQUIREMENTS_ERROR_MSG,
  LOGIN_FORM_ACTION,
  LOGIN_FORM_SENTENCE,
  SERVER_DUPLICATE_ACCOUNT_ERROR_MSG,
} from "../constants";
import { onEnterOrSpaceDownPropsBuilder } from "../utils";
import CreateAccountFormActivate from "./create-account-form-activate";
import CreateAccountFormEmail from "./create-account-form-email";
import CreateAccountFormName from "./create-account-form-name";
import CreateAccountFormPassword from "./create-account-form-password";
import CreateAccountFormVerify from "./create-account-form-verify";
import GoBackHeader from "./go-back-header";
import LoginFormBodySectionSsoLogin from "./login-form-body-section-sso-login";

type Props = {
  switchToLogin: () => void,
  containerFlow: string,
  uiFlow: string,
  goToUiCreateEmail: () => void,
  goToUiCreateNames?: () => void,
  goToUiCreatePassword: () => void,
  goToUiCreateVerify: () => void,
  goToUiCreateActivate: () => void,
  goToSSOLogin: () => void,
};

type State = {
  emailAddress: string,
  password: string,
  firstName: string,
  lastName: string,
  activateEmailAddress: string,
  passwordRequirements: {
    regex: string,
    message: string,
  },
  isLoading: boolean,
  emailErrorMessage: string,
  firstNameErrorMessage: string,
  createAccountErrorMessage: string,
  isSsoSupported: boolean,
};

class CreateAccountForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      emailAddress: "",
      password: "",
      firstName: "",
      lastName: "",
      activateEmailAddress: "",
      passwordRequirements: { regex: "", message: "" },
      firstNameErrorMessage: "",
      emailErrorMessage: "",
      createAccountErrorMessage: "",
      isLoading: false,
      isSsoSupported: false,
    };
  }

  // Update emailAddress on input change
  updateEmailAddress = (event: SyntheticKeyboardEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    this.setState({ emailAddress: value });
    this.resetEmailErrorMessage();
  };

  clearEmailAddress = () => {
    this.setState({ emailAddress: "" });
    this.resetEmailErrorMessage();
  };

  submitEmail = (
    event: SyntheticEvent<HTMLFormElement>
  ): Promise<any> | void => {
    event.preventDefault();
    // Check for email address activation first
    const { emailAddress } = this.state;
    // TODO(kevin): replace hardcode value with API integration
    const HARDCODE_ACTIVATE_ACCOUNT_EMAIL = "kevin_todo@pepperdata.com";
    if (emailAddress === HARDCODE_ACTIVATE_ACCOUNT_EMAIL) {
      this.props.goToUiCreateActivate();
      return Promise.resolve();
    }
    fetch(
      "/api/auth/usernameIsSSO?username=" + encodeURIComponent(emailAddress),
      {
        method: "POST",
      }
    )
      .then((response) => response.json())
      .then((responseJSON) => {
        const isSsoSupported = responseJSON["isSSO"];
        this.setState({
          isSsoSupported,
        });
        if (isSsoSupported) {
          this.props.goToSSOLogin && this.props.goToSSOLogin();
        }
      });
    this.props.goToUiCreateNames && this.props.goToUiCreateNames();
  };

  getAndSetPasswordRequirements = (): Promise<any> => {
    const { emailAddress } = this.state;
    this.setState({ isLoading: true });

    return fetchPasswordRequirements(emailAddress)
      .then((response) => {
        if (response && response.regex && response.message) {
          this.setState({
            passwordRequirements: response,
            isLoading: false,
          });
          this.props.goToUiCreatePassword();
        } else {
          throw Error(EMAIL_PW_REQUIREMENTS_ERROR_MSG);
        }
      })
      .catch((err) => {
        // Display error message on Email UI screen
        console.error(err);
        const errorMessage = EMAIL_PW_REQUIREMENTS_ERROR_MSG;
        this.setState({
          isLoading: false,
          emailErrorMessage: errorMessage,
        });
      });
  };

  resetEmailErrorMessage = () => {
    this.setState({ emailErrorMessage: "" });
  };

  // Update password on input change
  updatePassword = (event: SyntheticKeyboardEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    this.setState({ password: value });
    this.resetCreateAccountErrorMessage();
  };

  updateFirstName = (event: SyntheticKeyboardEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    this.setState({ firstName: value });
    this.resetCreateAccountErrorMessage();
  };

  updateLastName = (event: SyntheticKeyboardEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    this.setState({ lastName: value });
    this.resetCreateAccountErrorMessage();
  };

  submitNames = (event: SyntheticEvent<HTMLFormElement>): Promise<any> => {
    event.preventDefault();

    // Get password requirements after email has been submitted
    return this.getAndSetPasswordRequirements();
  };

  clearPassword = () => {
    this.setState({ password: "" });
    this.resetCreateAccountErrorMessage();
  };

  clearFirstName = () => {
    this.setState({ firstName: "" });
    this.resetCreateAccountErrorMessage();
  };

  clearLastName = () => {
    this.setState({ lastName: "" });
    this.resetCreateAccountErrorMessage();
  };

  submitPassword = (event: SyntheticEvent<HTMLFormElement>): Promise<any> => {
    event.preventDefault();
    this.resetCreateAccountErrorMessage();
    return this.createAccountRequest();
  };

  createAccountRequest = (): Promise<any> => {
    const { emailAddress, password, firstName, lastName } = this.state;
    this.setState({ isLoading: true });

    return createAccount(emailAddress, password, firstName, lastName)
      .then(() => {
        this.setState({ isLoading: false });
        this.props.goToUiCreateVerify();
      })
      .catch((err) => {
        // Display appropriate error message
        console.error(err);

        if (
          err &&
          err.message &&
          _.isString(err.message) &&
          err.message.indexOf(SERVER_DUPLICATE_ACCOUNT_ERROR_MSG) !== -1
        ) {
          // When the error is for duplicate account, redirect UI back to
          // email address and place error below email input field.

          const emailErrorMessage = DUPLICATE_ACCOUNT_ERROR_MSG;
          this.setState({
            isLoading: false,
            emailErrorMessage,
          });
          this.props.goToUiCreateEmail();
        } else {
          // All other errors will display a general error message.

          const createAccountErrorMessage = ACCOUNT_CREATION_ERROR_MSG;
          this.setState({
            isLoading: false,
            createAccountErrorMessage,
          });
        }
      });
  };

  resetCreateAccountErrorMessage = () => {
    this.setState({ createAccountErrorMessage: "" });
  };

  // Update activateEmailAddress on input change
  updateActivateEmailAddress = (
    event: SyntheticKeyboardEvent<HTMLInputElement>
  ) => {
    const value = event.currentTarget.value;
    this.setState({ activateEmailAddress: value });
  };

  clearActivateEmailAddress = () => {
    this.setState({ activateEmailAddress: "" });
  };

  submitActivateEmailAddress = (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    // TODO(kevin): hook up activate account by email address
    alert("Submitted Activate Account");
  };

  goToUiCreateEmailAndClearError = () => {
    this.resetCreateAccountErrorMessage();
    this.props.goToUiCreateEmail();
  };

  goToUiCreateNameAndClearError = () => {
    this.resetCreateAccountErrorMessage();

    this.props.goToUiCreateNames && this.props.goToUiCreateNames();
  };

  render() {
    const { switchToLogin, containerFlow, uiFlow } = this.props;

    const {
      emailAddress,
      password,
      firstName,
      lastName,
      activateEmailAddress,
      passwordRequirements,
      firstNameErrorMessage,
      emailErrorMessage,
      createAccountErrorMessage,
      isLoading,
    } = this.state;

    const isDisabled = isLoading;
    const topSectionClassNames = isDisabled ? "disabled" : "";
    const switchToLoginIfNotDisabled = isDisabled ? () => {} : switchToLogin;

    return (
      <div className={`pd-create-account-form ${uiFlow}`}>
        {/* Top Section */}
        <div
          className={`top-section ${topSectionClassNames}`}
          onClick={switchToLoginIfNotDisabled}
          {...onEnterOrSpaceDownPropsBuilder(switchToLoginIfNotDisabled)}
        >
          <span className="action">{LOGIN_FORM_ACTION}</span>{" "}
          <span>{LOGIN_FORM_SENTENCE}</span>
        </div>

        {/* Main Section */}
        <div className="main-section">
          <div className="main-header-section">
            <div>
              <span className="action">{CREATE_FORM_ACTION}</span>{" "}
              <span>{CREATE_FORM_SENTENCE}</span>
            </div>
          </div>

          {/* Body Sections */}
          <div className="body-sections">
            <CreateAccountFormEmail
              containerFlow={containerFlow}
              uiFlow={uiFlow}
              emailAddress={emailAddress}
              updateEmailAddress={this.updateEmailAddress}
              clearEmailAddress={this.clearEmailAddress}
              onSubmit={this.submitEmail}
              emailErrorMessage={emailErrorMessage}
              isLoading={isLoading}
            />
            <CreateAccountFormName
              containerFlow={containerFlow}
              uiFlow={uiFlow}
              firstName={firstName}
              lastName={lastName}
              updateFirstName={this.updateFirstName}
              updateLastName={this.updateLastName}
              clearFirstName={this.clearFirstName}
              clearLastName={this.clearLastName}
              emailAddress={emailAddress}
              onSubmit={this.submitNames}
              firstNameErrorMessage={firstNameErrorMessage}
              isLoading={isLoading}
              backButtonOnClick={this.goToUiCreateEmailAndClearError}
            />
            <CreateAccountFormPassword
              containerFlow={containerFlow}
              uiFlow={uiFlow}
              password={password}
              updatePassword={this.updatePassword}
              clearPassword={this.clearPassword}
              onSubmit={this.submitPassword}
              passwordRequirements={passwordRequirements}
              createAccountErrorMessage={createAccountErrorMessage}
              resetCreateAccountErrorMessage={
                this.resetCreateAccountErrorMessage
              }
              isLoading={isLoading}
              name={firstName + " " + lastName}
              backButtonOnClick={this.goToUiCreateNameAndClearError}
            />
            <CreateAccountFormVerify
              containerFlow={containerFlow}
              uiFlow={uiFlow}
            />
            <CreateAccountFormActivate
              containerFlow={containerFlow}
              uiFlow={uiFlow}
              activateEmailAddress={activateEmailAddress}
              updateActivateEmailAddress={this.updateActivateEmailAddress}
              clearActivateEmailAddress={this.clearActivateEmailAddress}
              onSubmit={this.submitActivateEmailAddress}
            />
            {this.state.isSsoSupported && (
              <div
                className="body-section new-account-form-sso-login"
                style={{
                  visibility:
                    containerFlow === CONTAINER_CREATE &&
                    uiFlow === CREATE_FORM_SSO_LOGIN
                      ? "visible"
                      : "hidden",
                }}
              >
                <GoBackHeader
                  goBackActionFunc={this.props.goToUiCreateEmail}
                  textToDisplay={emailAddress}
                />
                <div>
                  <LoginFormBodySectionSsoLogin onSubmit={(event) => {
                    event.preventDefault();
                    ssoLogin(emailAddress);
                  }} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default CreateAccountForm;
